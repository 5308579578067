<template>
  <iframe src="/globe/index.html" title="Self Biography Globe" frameborder="0" allowtransparency="true"></iframe>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
  }),
  beforeMount() {
    this.$store.commit('setLogin', true);
  },
  mounted() {
  },
}
</script>

<style scoped lang="scss">
  iframe {
    width: 100%;
    height: 80vh;
    max-height: 100%;
  }
</style>
